import React, { Component } from 'react';
import styled from 'styled-components';
import PrimaryNavigation from '../atoms/PrimaryNavigation';
import SecondaryNavigation from '../atoms/SecondaryNavigation';

const Holder = styled.div`
  width: 100%;
  min-height: calc(100vh - 4rem);
  border-top: 1px solid ${props => props.theme.colours.darkGrey};
  padding: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  background: ${props => props.theme.colours.black};
  position: relative;
  a {
    text-decoration: none;
    position: relative;
    :after {
      content: '';
      opacity: 0;
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 50%;
      background-color: ${props => props.theme.colours.green};
      position: absolute;
      top: 50%;
      left: calc( 100% + 1rem );
      transition: opacity 0.25s;
    }
    &:hover:after { opacity: 1; }
    &[aria-current="page"]:after {
      opacity: 1;
      background-color: ${props => props.theme.colours.yellow};
    }
  }
`;

const PrimaryHolder = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: start;
  a {
    margin: 0.2rem 0;
  }
`;

const SecondaryHolder = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 2.8rem;
  position: relative;
  a {
    margin: 0.2rem 0;
  }
`;

class SmallNavigation extends Component {
  render() {
    return (
      <Holder>
        <PrimaryHolder>
          <PrimaryNavigation/>
        </PrimaryHolder>
        <SecondaryHolder>
          <SecondaryNavigation/>
        </SecondaryHolder>
      </Holder>

    )
  }
}

export default SmallNavigation;
