import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Holder = styled.div`
  width: 100%;
  height: ${props => props.positioning === 'absolute' ? '100%' : 'auto'};
  position: relative;
  overflow: hidden;
  > :first-child {
    transition: transform 1s;
  }
  &:hover {
    > :first-child {
      transform: ${props => props.hoverEffect ? 'scale(1.1)' : ''};
    }
  }
`;

const Fade = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: ${props => props.fadeDirection === 'up' ? 'linear-gradient(0deg, rgba(12,12,12,0) 0%, rgba(12,12,12,1) 100%)' : 'linear-gradient(0deg, rgba(12,12,12,1) 0%, rgba(12,12,12,0) 100%)'};

`;

class ImageFade extends Component {
  render() {
    const { fadeDirection, positioning, hoverEffect } = this.props;
    if(fadeDirection) {
      return (
        <Holder positioning={positioning} hoverEffect={hoverEffect}>
          {this.props.children}
          <Fade fadeDirection={fadeDirection}/>
        </Holder>
      )
    } else {
      return this.props.children;
    }
  }
}

ImageFade.propTypes = {
  fadeDirection: PropTypes.oneOf( [ 'up', 'down' ] ),
  positioning: PropTypes.oneOf( [ 'relative', 'absolute' ] ),
  hoverEffect: PropTypes.bool,
};

ImageFade.defaultProps = {
  fadeDirection: 'down',
  positioning: 'relative',
  hoverEffect: true
};

export default ImageFade;
