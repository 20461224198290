import React, { Component } from 'react';
import { Transition } from 'react-transition-group';
import PropTypes from 'prop-types';
import SmallNavigation from './SmallNavigation';

const duration = 300;

const defaultStyle = {
  position: 'absolute',
  zIndex: '10',
  top: '100%',
  right: '100%',
  width: '100%',
  transition: `transform ${duration}ms ease-in-out`,
  transform: 'translateX(0)',
};

const transitionStyles = {
  entering: {
    transform: 'translateX(100%)',
  },
  entered: {
    transform: 'translateX(100%)',
  },
  exiting: {
    transform: 'translateX(0)',
  },
  exited: {
    transform: 'translateX(0)',
  },
};

class NavigationTransition extends Component {
  render() {
    return (
      <Transition in={this.props.open} timeout={duration}>
        {state => (
          <div style={{
            ...defaultStyle,
            ...transitionStyles[ state ]
          }}>
            <SmallNavigation/>
          </div>
        )}
      </Transition>
    )
  }
}

NavigationTransition.propTypes = {
  open: PropTypes.bool
};

NavigationTransition.defaultProps = {
  open: false
};

export default NavigationTransition;
