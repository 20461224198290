import React, { Component } from 'react';
import styled from 'styled-components';
import SharpImage from '../atoms/SharpImage';
import Container from '../atoms/Container';
import { StaticQuery, graphql } from 'gatsby'
import MarkdownConverter from '../atoms/MarkdownConverter';

const Holder = styled.footer`
  position: relative;
  margin-top: 20rem;
  padding-top: 12.5vh;
  padding-bottom: 12.5vh;
  a {
    text-decoration: none;
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4rem;
  position: relative;
  @media(${props => props.theme.breakpoints.sm}) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 6rem;
  }
  p {
    margin: 0;
  }
  img {
    margin: 2rem 2rem 0 0;
    max-width: 50px;
    max-height: 50px;
  }
`;

const BG = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
`;

class Footer extends Component {
  render() {
    return (
      <Holder>
        <Container>
          <Content>

            <StaticQuery
              query={graphql`
                query FooterQuery {
                  contentfulGlobalElements(title: {eq: "Global elements"}) {
                    footerColumnOne {
                      childMarkdownRemark {
                        rawMarkdownBody
                      }
                    }
                    footerColumnThree {
                      childMarkdownRemark {
                        rawMarkdownBody
                      }
                    }
                    footerColumnTwo {
                      childMarkdownRemark {
                        rawMarkdownBody
                      }
                    }
                  }
                }
              `}
              render={data => (
                <>
                  <MarkdownConverter content={data.contentfulGlobalElements.footerColumnOne.childMarkdownRemark.rawMarkdownBody}/>
                  <MarkdownConverter content={data.contentfulGlobalElements.footerColumnTwo.childMarkdownRemark.rawMarkdownBody}/>
                  <MarkdownConverter content={data.contentfulGlobalElements.footerColumnThree.childMarkdownRemark.rawMarkdownBody}/>
                </>
              )}
            />
          </Content>
        </Container>
        <BG>
          <SharpImage
            fadeDirection="up"
            imgName="hero_00012.png"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          />
        </BG>
      </Holder>
    )
  }
}

export default Footer;
