export const theme = {
  colours: {
    black: '#0c0c0c',
    white: '#d3d3d3',
    darkGrey: '#4A4A4A',
    yellow: 'rgb(252, 193, 5)',
    green: 'rgb(0, 129, 116)',
    // Links
    link: '#008174',
    visited: '#008174',
    // Accessibility and forms
    focus: '#008174',
    error: '#d0021b',
  },
  breakpoints: {
    sm: 'min-width: 576px',
    md: 'min-width: 768px',
    lg: 'min-width: 992px',
    xl: 'min-width: 1200px',
  },
  typography: {
    base: 1.6,
    scale: {
      xs: 1.067,
      sm: 1.067,
      md: 1.125,
      lg: 1.125,
    }
  },
  typeStyles: ( exp ) => {
    return `font-size: ${theme.typography.base * Math.pow( theme.typography.scale.xs, exp )}rem;
    @media ( ${theme.breakpoints.sm} ) {
      font-size: ${theme.typography.base * Math.pow( theme.typography.scale.sm, exp )}rem;
    }
    @media ( ${theme.breakpoints.md} ) {
      font-size: ${theme.typography.base * Math.pow( theme.typography.scale.md, exp )}rem;
    }
    @media ( ${theme.breakpoints.lg} ) {
      font-size: ${theme.typography.base * Math.pow( theme.typography.scale.lg, exp )}rem;
    }`
  },
};
