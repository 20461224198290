import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Footer from '../components/molecules/Footer';
import Transition from '../components/atoms/Transition';

import GlobalStyles from '../components/atoms/GlobalStyles';
import styled, { ThemeProvider } from 'styled-components';
import { theme } from '../utils/styling';
import Header from '../components/molecules/Header';

const Holder = styled.main`
  position: relative;
`;

class Index extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <GlobalStyles/>
        <Transition location={this.props.location}>
          <Holder>
            <Header/>
            {this.props.children}
            <Footer/>
          </Holder>
        </Transition>
      </ThemeProvider>
    )
  }
}


Index.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Index;
