// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-training-events-js": () => import("./../src/pages/training-events.js" /* webpackChunkName: "component---src-pages-training-events-js" */),
  "component---src-templates-category-js": () => import("./../src/templates/Category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-event-js": () => import("./../src/templates/Event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-news-archive-js": () => import("./../src/templates/NewsArchive.js" /* webpackChunkName: "component---src-templates-news-archive-js" */),
  "component---src-templates-news-js": () => import("./../src/templates/News.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-product-archive-js": () => import("./../src/templates/ProductArchive.js" /* webpackChunkName: "component---src-templates-product-archive-js" */),
  "component---src-templates-product-js": () => import("./../src/templates/Product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-training-calendar-js": () => import("./../src/templates/TrainingCalendar.js" /* webpackChunkName: "component---src-templates-training-calendar-js" */),
  "component---src-templates-training-js": () => import("./../src/templates/Training.js" /* webpackChunkName: "component---src-templates-training-js" */),
  "component---src-templates-vendor-archive-js": () => import("./../src/templates/VendorArchive.js" /* webpackChunkName: "component---src-templates-vendor-archive-js" */),
  "component---src-templates-vendor-js": () => import("./../src/templates/Vendor.js" /* webpackChunkName: "component---src-templates-vendor-js" */),
  "component---src-templates-vendor-training-js": () => import("./../src/templates/VendorTraining.js" /* webpackChunkName: "component---src-templates-vendor-training-js" */)
}

