import React, { Component } from 'react'
import { Index } from 'elasticlunr'
import styled from 'styled-components'
import IconSearch from '../atoms/IconSearch';
import SearchResult from '../atoms/SearchResult';

const Holder = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  min-width: 15rem;
  border-top: 1px solid ${props => props.theme.colours.darkGrey};
  border-bottom: 1px solid ${props => props.theme.colours.darkGrey};
  background-color: ${props => props.theme.colours.black};
  display: flex;
  @media(${props => props.theme.breakpoints.lg}) {
    position: relative;
    width: 15rem;
    border: none;
    top: auto;
    left: auto;
    justify-content: end;
    justify-self: end;
    background-color: transparent;
  }
  input[type="text"] {
    margin: 0;
    border-color: transparent;
    border-right: 1px solid ${props => props.theme.colours.darkGrey};
    width: calc(100% - 4rem - 1px);
    padding: 0.5rem 2rem;
    border-radius: 0;
    @media(${props => props.theme.breakpoints.lg}) {
      border: none;
      padding: 0.5rem 1rem;
      text-align: right;
      &:focus {
        border: none;
      }
    }
  }
  svg {
    width: 4rem;
    height: 4rem;
    padding: 1.2rem;
    @media(${props => props.theme.breakpoints.lg}) {
      width: 3rem;
      height: 3rem;
      padding: 0.8rem;
      margin-top: 0.5rem;
    }
  }
`;

const Results = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border-top: 1px solid;
  border-bottom: 1px solid;
  background-color: ${props => props.theme.colours.black};
  list-style: none;
  padding: 0;
  margin: 0;
  @media(${props => props.theme.breakpoints.lg}) {
    width: 40rem;
    max-height: 60vh;
    overflow-y: scroll;
    border: 1px solid;
    left: auto;
    right: 0;
    text-align: left;
  }
  p {
    margin: 0;
    padding: 1rem 2rem;
  }
  button {
    border: 0;
    padding: 1rem;
    text-align: right;
    ${props => props.theme.typeStyles( -4 )};
  }
`;

// Search component
class Search extends Component {
  state = {
    query: ``,
    results: [],
    searching: false,
  };

  getOrCreateIndex = () => this.index ? this.index : Index.load( this.props.searchIndex );
  // Create an elasticlunr index and hydrate with graphql query results

  search = evt => {
    const query = evt.target.value;
    this.index = this.getOrCreateIndex();
    this.setState( {
      query,
      // Query the index with search string to get an [] of IDs
      results: this.index
      .search( query, { expand: true } )
      // Map over each ID and return the full document
      .map( ( { ref } ) => this.index.documentStore.getDoc( ref ) ),
    } )
  };

  handleFocus = (e) => {
    this.setState( { searching: true } );
  };

  handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      this.setState( { searching: false } );
    }
  };

  render() {
    return (
      <Holder
        tabIndex="0"
        onFocus={( e ) => { this.handleFocus(e) }}
        // onBlur={( e ) => { this.handleBlur(e) }}
      >
        <input
          type="text"
          placeholder="Search"
          value={this.state.query}
          onChange={this.search}
        />
        <IconSearch/>
        {this.state.searching && <Results>
          {this.state.results.length > 0 && this.state.results.map( ( page, i ) => (
            <SearchResult key={i} page={page}/>
          ) )}
          {this.state.results.length === 0 && <p>No results...</p>}
        </Results>}
      </Holder>
    )
  }
}

export default Search;
