import React, { useState } from 'react';
import styled from 'styled-components';
import IconMenu from '../atoms/IconMenu';
import Logo from '../atoms/Logo';
import SearchHolder from './SearchHolder';
import NavigationTransition from './NavigationTransition';

const Holder = styled.header`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
  @media(${props => props.theme.breakpoints.lg}) {
    display: none;
  }
`;

const LogoRow = styled.div`
  width: 100%;
  display: grid;
  position: relative;
  z-index: 2;
  grid-template-columns: 4rem 1fr;
  align-items: center;
  background: ${props => props.theme.colours.black};
`;

const IconHolder = styled.div`
  width: 100%;
  padding: 1.2rem;
  border-right: 1px solid ${props => props.theme.colours.darkGrey};
  cursor: pointer;
  svg {
    width: 1.6rem;
    height: 1.6rem;
    display: block;
  }
`;

const LogoHolder = styled.div`
  width: 20rem;
  padding-left: 2rem;
`;

function SmallHeader() {

  const [ open, setOpen ] = useState( false );

  return (
    <Holder>
      <LogoRow>
        <IconHolder onClick={() => setOpen( !open )}>
          <IconMenu/>
        </IconHolder>
        <LogoHolder>
          <Logo/>
        </LogoHolder>
        <SearchHolder/>
      </LogoRow>
      <NavigationTransition open={open}/>
    </Holder>
  )
}

export default SmallHeader;
