import React from 'react';
import SmallHeader from './SmallHeader';
import LargeHeader from './LargeHeader';

function Header() {
    return (
      <>
        <SmallHeader/>
        <LargeHeader/>
      </>
    )
}

export default Header;
