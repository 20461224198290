import React, { Component } from 'react';

class IconMenu extends Component {
  render() {
    return (
      <svg width="12px" height="7px" viewBox="0 0 12 7" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>Icon Menu</title>
        <g id="Symbols" stroke="#D8D8D8" strokeWidth="1" fill="none" fillRule="evenodd">
          <line x1="0" y1="0.5" x2="11.8945313" y2="0.5" id="top"/>
          <line x1="0" y1="3.5" x2="11.8945313" y2="3.5" id="middle"/>
          <line x1="0" y1="6.5" x2="11.8945313" y2="6.5" id="bottom"/>
        </g>
      </svg>
    )
  }
}

export default IconMenu;
