import React, { Component } from 'react';
import { Link } from 'gatsby';

class PrimaryNavigation extends Component {
  render() {
    return (
      <>
        <Link to="/">Home</Link>
        <Link to="/products/">Products</Link>
        <Link to="/vendors/">Vendors</Link>
        <Link to="/training-events/">Training</Link>
        <Link to="/news/">News</Link>
        <Link to="/about/">About</Link>
        <Link to="/contact/">Contact</Link>
      </>
    )
  }
}

export default PrimaryNavigation;
