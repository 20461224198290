import React, { Component } from 'react';

class IconSearch extends Component {
  render() {
    return (
      <svg width="23px" height="23px" viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>Icon Search</title>
        <g id="IconSearch" stroke="#D8D8D8" strokeWidth="2" fill="none" fillRule="evenodd">
          <circle id="Oval" cx="10" cy="10" r="9"/>
          <line x1="17" y1="17" x2="22" y2="22" id="Path"/>
        </g>
      </svg>
    )
  }
}

export default IconSearch;
