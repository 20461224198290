import React, { Component } from 'react';
import styled from 'styled-components';
import PrimaryNavigation from '../atoms/PrimaryNavigation';
import SearchHolder from './SearchHolder';

const Holder = styled.div`
  justify-self: end;
  a {
    text-decoration: none;
    position: relative;
    :after {
      content: '';
      opacity: 0;
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 50%;
      background-color: ${props => props.theme.colours.green};
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      transition: opacity 0.25s;
    }
    &:hover:after { opacity: 1; }
    &[aria-current="page"]:after {
      opacity: 1;
      background-color: ${props => props.theme.colours.yellow};
    }
  }
`;

const PrimaryHolder = styled.nav`
  display: flex;
  a {
    margin: 0.6rem 1rem 0;
    white-space: nowrap;
  }
`;

class LargeNavigation extends Component {
  render() {
    return (
      <Holder>
        <PrimaryHolder>
          <PrimaryNavigation/>
          <SearchHolder/>
        </PrimaryHolder>
      </Holder>

    )
  }
}

export default LargeNavigation;
