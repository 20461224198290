import React, { Component } from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';

class SecondaryNavigation extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
                  query SecondaryNavigationQuery {
                    allContentfulCategory(sort: {order: ASC, fields: order}) {
                      nodes {
                        id
                        title
                        slug
                      }
                    }
                  }
                `}
        render={data => {
          return (
            data.allContentfulCategory.nodes.map( node => (
              <Link key={node.id} to={'/category/' + node.slug}>{node.title}</Link>
            ) )
          )
        }}/>
    )
  }
}

export default SecondaryNavigation;
