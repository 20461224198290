import { createGlobalStyle } from 'styled-components';
import plexItalicWoff from '../../fonts/IBMPlexMono-Italic.woff';
import plexItalicWoff2 from '../../fonts/IBMPlexMono-Italic.woff2';
import plexWoff from '../../fonts/IBMPlexMono-Regular.woff';
import plexWoff2 from '../../fonts/IBMPlexMono-Regular.woff2';

const GlobalStyle = createGlobalStyle` 

  @font-face {
    font-family: 'IBMPlexMono';
    src: url(${plexWoff2}) format('woff2'),
    url(${plexWoff}) format('woff');
    font-weight: normal;
  }
  @font-face {
    font-family: 'IBMPlexMono';
    src: url(${plexItalicWoff2}) format('woff2'),
    url(${plexItalicWoff}) format('woff');
    font-weight: normal;
    font-style: italic;
  }
  
  html {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      // Set rems
      font-size: 56.25%; // 1rem = 9px
      @media ( ${props => props.theme.breakpoints.sm} ) {
          font-size: 56.25%; // 1rem = 9px
      }
      @media ( ${props => props.theme.breakpoints.md} ) {
          font-size: 62.5%; // 1rem = 10px
      }
      @media ( ${props => props.theme.breakpoints.lg} ) {
          font-size: 62.5%; // 1rem = 10px
      }
      * { box-sizing: border-box; }
  }

  body {
      margin: 0;
      // Use system fonts: https://css-tricks.com/snippets/css/system-font-stack/
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      color: ${props => props.theme.colours.white};
      background-color: ${props => props.theme.colours.black};
  }

  h1, h2, h3, h4, h5, h6,
  p, .p,
  ol, ul, li,
  code, kbd, pre, samp,
  nav a {
      line-height: 1.5;
      margin-top: 2rem;
      margin-bottom: 2rem;
  }

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
      font-weight: normal;
      a { text-decoration: none; }
      a:hover { text-decoration: none; }
      &.small { ${props => props.theme.typeStyles( -2 )}; }
  }

  h1, .h1 { ${props => props.theme.typeStyles( 5 )}; }
  h2, .h2 { ${props => props.theme.typeStyles( 4 )}; }
  h3, .h3 { ${props => props.theme.typeStyles( 3 )}; }
  h4, .h4 { ${props => props.theme.typeStyles( 2 )}; }
  h5, .h5 { ${props => props.theme.typeStyles( 1 )}; }
  h6, .h6 { ${props => props.theme.typeStyles( 0 )}; }
  p, .p, ul, ol, blockquote, nav a { ${props => props.theme.typeStyles( 0 )}; }

  p.small { ${props => props.theme.typeStyles( -2 )}; }

  li {
      ${props => props.theme.typeStyles( 0 )};
      margin-top: 0;
      margin-bottom: 0;
  }

  small {  
    display: block;
    line-height: 1.5;
    font-weight: normal;
    ${props => props.theme.typeStyles( -2 )}
  }
  
  code, kbd, pre, samp {
      font-family: monospace;
      font-size: 16px;
      white-space: normal;
  }
  
  pre { font-size: 16px; }
  
  ul {
      padding-left: 4rem;
      list-style-type: disc;
  }
  
  ol {
      padding-left: 4rem;
      list-style-type: decimal;
  }
  
  em, i { font-style: italic; }
  
  strong, b { font-weight: bold; }
  
  blockquote {
      font-weight: bold;
      padding-left: 4rem;
  }
  
  a { color: inherit; }
  
  video {
    /* override other styles to make responsive */
    width: 100%    !important;
    height: auto   !important;
    margin-bottom: 2rem;
  }
  
  .jump-target::before {
    content:"";
    display:block;
    height:10rem; /* fixed header height*/
    margin:-10rem 0 0; /* negative fixed header height */
  }
  
  sup, sub {
      vertical-align: baseline;
      position: relative;
      top: -0.4em;
  }
  
  sub { top: 0.4em; }
  
  label {
    ${props => props.theme.typeStyles( -2 )};
    line-height: 1.2;
    font-weight: normal;
  }
  
  .text-input,
  input[type="text"],
  input[type="password"],
  input[type="date"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="range"],
  input[type="search"],
  input[type="tel"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  textarea {
      display: block;
      font-family:inherit;
      ${props => props.theme.typeStyles( 0 )};
      width: 100%;
      padding: 0.4rem 1rem;
      margin-top: 0.5rem;
      margin-bottom: 2rem;
      color: inherit;
      border: 1px solid ${props => props.theme.colours.darkGrey};
      border-radius: 0.5rem;
      line-height: 1.6;
      background-color: transparent;
      -webkit-appearance: none;
      &:focus {
          border: 1px ${props => props.theme.colours.focus} solid;
      }
  }
  
  .file-input,
  input[type="file"],
  .radio-input,
  input[type="radio"],
  .checkbox-input,
  input[type="checkbox"],
  select {
      font-family:inherit;
  }
  
  .button,
  button,
  input[type="submit"],
  input[type="button"],
  input[type="reset"] {
      display: inline-block;
      cursor: pointer;
  
      padding: 0 2rem;
  
      font-family:inherit;
      ${props => props.theme.typeStyles( -2 )};
      line-height: 3rem;
      text-decoration: none;
      white-space: nowrap;
      border: 1px solid ${props => props.theme.colours.green};
      border-radius: 1.5rem;
  
      color: ${props => props.theme.colours.white};
      background: transparent;
      position: relative;
      overflow: hidden;
      transition: all 0.25s;
      &:hover {
        color: ${props => props.theme.colours.black};
        border-color: transparent;
      }
      :after {
        opacity: 0;
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(45deg, ${props => props.theme.colours.green} 0%, ${props => props.theme.colours.yellow} 100%);
        transition: opacity 0.25s;
      }
      &:hover:after {
        opacity: 1;
      }
      &.large {
        @media(${props => props.theme.breakpoints.md}) {
          ${props => props.theme.typeStyles( 0 )};
          padding: 0 2rem;
          line-height: 4rem;
          border-radius: 2rem;
        }
      }
      &.link {
        padding: 0;
        border: 0;
        ${props => props.theme.typeStyles( 0 )};
        text-decoration: underline;
        background: none;
        color: inherit;
        line-height: inherit;
        border-radius: 0;
        :after {
          display: none;
        }
      }
  }
  
  input[type="submit"] {
    &:hover {
      color: ${props => props.theme.colours.black};
      border-color: ${props => props.theme.colours.black};
      background: linear-gradient(45deg, ${props => props.theme.colours.green} 0%, ${props => props.theme.colours.yellow} 100%);
    }
  }
  
  .errorMsg {
      color: ${props => props.theme.colours.error};
  }
`;

export default GlobalStyle;
