import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const Holder = styled.li`
  padding: 0 2rem;
  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${props => props.theme.colours.darkGrey};
    padding: 1rem 0;
    text-decoration: none;
    :after {
      display: none;
    }
    span {
      white-space: normal;
    }
  }
  &:last-child a {
    border-bottom: none;
  }
`;

class SearchResult extends Component {
  render() {
    const { page } = this.props;
    return (
      <Holder>
        <Link to={page.slug}><span>{page.title}</span>
          <small>{page.type}</small>
        </Link>
      </Holder>
    )
  }
}

SearchResult.propTypes = {
  page: PropTypes.object
};

export default SearchResult;
