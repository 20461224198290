import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import ImageFade from './ImageFade';

class SharpImage extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            allImageSharp {
              edges {
                node {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                    originalName
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const { imgName, style, imgStyle, fadeDirection, positioning, hoverEffect } = this.props;
          const image = data.allImageSharp.edges.find(
            edge => edge.node.fluid.originalName === imgName.replace( /^.*[\\/]/, '' )
          );
          if ( !image ) {
            return null
          }
          return (
            <ImageFade
              hoverEffect={hoverEffect}
              fadeDirection={fadeDirection}
              positioning={positioning}>
              <Img
                style={style}
                imgStyle={imgStyle}
                fluid={image.node.fluid}/>
            </ImageFade>
          )
        }}
      />
    )
  }
}

SharpImage.propTypes = {
  imgName: PropTypes.string.isRequired,
  style: PropTypes.object,
  imgStyle: PropTypes.object,
  fadeDirection: PropTypes.oneOf( [ 'up', 'down' ] ),
  positioning: PropTypes.oneOf( [ 'relative', 'absolute' ] ),
  hoverEffect: PropTypes.bool,
};

SharpImage.defaultProps = {
  fadeDirection: 'down',
  positioning: 'absolute',
};

export default SharpImage;
