import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const Holder = styled.div`
  a {
    display: block;
    text-decoration: none;
    color: inherit;
  }
  svg {
    display: block;
    width: 100%;
    height: auto;
  }
`;

function Logo( props ) {
  const { lines } = props;
  return (
    <Holder>
      <Link to="/">
        {lines && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1229.79 270.56">
          <title>Fulcrum Management</title>
          <g className="lines">
            <path d="M1225.23,136.13s-168.22.7-281.78,8.94c-137.67,10-325.19,47.11-325.19,47.11-97.89,18.1-243.22,44-329.92,50.07-9.37.65-77,5.32-153.1,3.28S4.86,233.67,4.86,233.67l1-7.27s54.32,9.82,130.38,11.86S280,235.64,289.35,235c86.7-6,236-30.55,333.86-48.65,0,0,198.53-35.16,312.11-43.34s288.43-7.36,288.43-7.36l1.48.49" fill="#fcc105"/>
            <path d="M1223.48,140s-168,.79-281.37,10.11c-137.48,11.31-324.73,53.3-324.73,53.3C519.63,223.84,374.51,253.21,287.93,260a1499,1499,0,0,1-152.88,3.7C59.1,261.41,4.86,250.3,4.86,250.3l1-8.21s54.25,11.1,130.2,13.41a1495.77,1495.77,0,0,0,152.88-3.7c86.58-6.81,235.62-34.56,333.38-55.05,0,0,198.25-39.78,311.67-49s288-8.32,288-8.32l1.48.55" fill="#39978c"/>
          </g>
          <g>
            <path d="M5.54,4.72H68.4V20.21H23.84V40.48H62.9V55.8H23.84v37H5.54Z" fill="#fff"/>
            <path d="M96,27.57V66.86q0,5.56,1.31,8.37,2.32,5,9.1,5,8.7,0,11.91-7.06A25.68,25.68,0,0,0,120,63V27.57h17.29V92.76H120.67V83.55c-.16.2-.56.8-1.19,1.79A11.72,11.72,0,0,1,117.21,88a23.6,23.6,0,0,1-7.72,4.91,26.48,26.48,0,0,1-8.74,1.31q-14.43,0-19.44-10.4Q78.51,78,78.51,66.86V27.57Z" fill="#fff"/>
            <path d="M171.21,92.76H154.16V4.6h17.05Z" fill="#fff"/>
            <path d="M226.83,51a15.14,15.14,0,0,0-2.46-6.58q-2.88-3.94-8.93-3.95-8.64,0-11.81,8.56A35.47,35.47,0,0,0,202,61a32.6,32.6,0,0,0,1.68,11.51q3.06,8.14,11.51,8.14,6,0,8.51-3.23a16.09,16.09,0,0,0,3.06-8.38h17.41a28.55,28.55,0,0,1-5.63,14.72Q230.46,95,214.72,95t-23.17-9.33q-7.43-9.33-7.42-24.19,0-16.79,8.2-26.11T215,26q12.27,0,20.09,5.5T244.3,51Z" fill="#fff"/>
            <path d="M292.17,26l1.47.09V43.59c-1.08-.12-2-.19-2.87-.23s-1.52-.06-2-.06q-10.28,0-13.81,6.69c-1.32,2.52-2,6.38-2,11.61V92.76H255.78V27.57h16.27V38.93Q276,32.41,278.93,30q4.79-4,12.44-4C291.68,26,292,26,292.17,26Z" fill="#fff"/>
            <path d="M320.61,27.57V66.86q0,5.56,1.31,8.37,2.32,5,9.11,5,8.69,0,11.9-7.06A25.67,25.67,0,0,0,344.6,63V27.57h17.28V92.76H345.31V83.55c-.16.2-.55.8-1.19,1.79A11.94,11.94,0,0,1,341.86,88a23.64,23.64,0,0,1-7.73,4.91,26.44,26.44,0,0,1-8.74,1.31Q311,94.19,306,83.79q-2.81-5.75-2.8-16.93V27.57Z" fill="#fff"/>
            <path d="M459.79,27.8a19,19,0,0,1,7.59,5.87A18.66,18.66,0,0,1,471.09,42a57.65,57.65,0,0,1,.6,9.63l-.12,41.09H454.11V51.25a13.58,13.58,0,0,0-1.2-6.1q-2.28-4.55-8.37-4.55-7.07,0-9.75,5.87a18.32,18.32,0,0,0-1.38,7.47V92.76H416.25V53.94q0-5.8-1.2-8.43c-1.43-3.15-4.25-4.73-8.43-4.73q-7.31,0-9.81,4.73-1.38,2.68-1.38,8V92.76H378.15V27.69h16.57v9.5a27.27,27.27,0,0,1,6-7.23q5-3.83,12.86-3.83,7.47,0,12.08,3.29a19.21,19.21,0,0,1,5.62,7.83,21.73,21.73,0,0,1,8.31-8.43,25.44,25.44,0,0,1,11.73-2.69A22.8,22.8,0,0,1,459.79,27.8Z" fill="#fff"/>
            <path d="M522.47,4.9h17l25.26,74.28L589.87,4.9H606.8V92.76H595.44V40.9c0-1.79,0-4.76.11-8.91s.12-8.59.12-13.34L570.59,92.76H558.8L533.47,18.65v2.7q0,3.22.18,9.83t.18,9.72V92.76H522.47Z" fill="#fff"/>
            <path d="M657.7,54q3.7-.48,5-3.1a9.51,9.51,0,0,0,.72-4.15c0-3.68-1.31-6.35-3.91-8s-6.34-2.49-11.19-2.49q-8.41,0-11.94,4.57-2,2.52-2.57,7.51h-10Q624,36.44,631.4,31.8a31.41,31.41,0,0,1,17.12-4.65q11.28,0,18.34,4.3t7,13.4V81.76a4.73,4.73,0,0,0,.68,2.69c.46.67,1.43,1,2.9,1q.72,0,1.62-.09c.6-.06,1.23-.15,1.91-.27v8a29.23,29.23,0,0,1-3.83.89,27.71,27.71,0,0,1-3.58.18q-5.56,0-8.08-3.94a15.24,15.24,0,0,1-1.85-5.92,27.48,27.48,0,0,1-9.45,7.47,29.31,29.31,0,0,1-13.58,3.17q-8.91,0-14.56-5.41A17.92,17.92,0,0,1,620.38,76q0-8.89,5.56-13.79t14.59-6ZM635,83.08a12.64,12.64,0,0,0,8.07,2.68,24.62,24.62,0,0,0,11-2.63q9-4.35,9-14.27V60.2A18.38,18.38,0,0,1,658,62.3a43.07,43.07,0,0,1-6.09,1.2l-6.51.84a25.13,25.13,0,0,0-8.8,2.44q-5,2.81-5,9A8.86,8.86,0,0,0,635,83.08Z" fill="#fff"/>
            <path d="M691.49,28.7h10.23v9.09a28.7,28.7,0,0,1,9.63-8.07,25.55,25.55,0,0,1,11.3-2.45q13.64,0,18.42,9.51,2.63,5.2,2.63,14.89V92.76H732.76V52.39A21.79,21.79,0,0,0,731,42.94q-2.87-6-10.4-6a21.44,21.44,0,0,0-6.28.77A16.1,16.1,0,0,0,706.56,43a15.86,15.86,0,0,0-3.5,6.55,43.88,43.88,0,0,0-.81,9.65V92.76H691.49Z" fill="#fff"/>
            <path d="M793.94,54q3.7-.48,5-3.1a9.68,9.68,0,0,0,.71-4.15q0-5.52-3.91-8t-11.19-2.49q-8.41,0-11.94,4.57-2,2.52-2.56,7.51H760q.3-11.88,7.68-16.52a31.38,31.38,0,0,1,17.11-4.65q11.29,0,18.34,4.3t7,13.4V81.76a4.73,4.73,0,0,0,.69,2.69c.46.67,1.42,1,2.9,1a16.09,16.09,0,0,0,1.61-.09q.9-.09,1.92-.27v8a29.61,29.61,0,0,1-3.83.89,27.89,27.89,0,0,1-3.59.18q-5.56,0-8.07-3.94a15.23,15.23,0,0,1-1.86-5.92,27.29,27.29,0,0,1-9.45,7.47,29.24,29.24,0,0,1-13.57,3.17q-8.93,0-14.57-5.41A17.92,17.92,0,0,1,756.62,76q0-8.89,5.56-13.79t14.6-6ZM771.27,83.08a12.69,12.69,0,0,0,8.08,2.68,24.58,24.58,0,0,0,11-2.63q9-4.35,9-14.27V60.2a18.25,18.25,0,0,1-5.07,2.1,43.07,43.07,0,0,1-6.09,1.2l-6.5.84a25.06,25.06,0,0,0-8.8,2.44q-5,2.81-5,9A8.85,8.85,0,0,0,771.27,83.08Z" fill="#fff"/>
            <path d="M863.34,31.27a29.42,29.42,0,0,1,6.2,6.1V29.3h9.93V87.55q0,12.21-3.58,19.26-6.67,13-25.21,13-10.32,0-17.35-4.63t-7.87-14.51h10.95a12.13,12.13,0,0,0,3.09,6.64q3.63,3.59,11.42,3.59,12.32,0,16.12-8.73,2.26-5.14,2.08-18.36a21,21,0,0,1-7.74,7.29q-4.53,2.4-12,2.4a25.53,25.53,0,0,1-18.14-7.39q-7.77-7.4-7.77-24.43,0-16.09,7.83-25.12t18.92-9A23.18,23.18,0,0,1,863.34,31.27Zm1.32,11.55a15.59,15.59,0,0,0-12.44-5.74q-11.29,0-15.47,10.64-2.19,5.69-2.2,14.89,0,10.83,4.38,16.48a14.07,14.07,0,0,0,11.75,5.65q11.54,0,16.24-10.46a33.87,33.87,0,0,0,2.62-13.82Q869.54,48.57,864.66,42.82Z" fill="#fff"/>
            <path d="M935.42,30.47a24.24,24.24,0,0,1,9.69,8.28,28.65,28.65,0,0,1,4.28,11.3,74.9,74.9,0,0,1,1,14.12H903.75q.3,9.78,4.57,15.68t13.23,5.91q8.37,0,13.36-5.59a19,19,0,0,0,4-7.51h10.59a22.28,22.28,0,0,1-2.79,7.87,28,28,0,0,1-5.27,7.08,24.76,24.76,0,0,1-12,6.46,36.2,36.2,0,0,1-8.72,1,26.73,26.73,0,0,1-20.15-8.7q-8.26-8.7-8.26-24.37,0-15.43,8.32-25.06t21.76-9.63A28.8,28.8,0,0,1,935.42,30.47Zm3.86,25.15a28.18,28.18,0,0,0-3-11.19q-4.38-7.77-14.65-7.77A16.16,16.16,0,0,0,909.27,42,20.33,20.33,0,0,0,904,55.62Z" fill="#fff"/>
            <path d="M964,28.7h10.65v9.09a36,36,0,0,1,6.93-6.88,20.89,20.89,0,0,1,12.08-3.64c5.11,0,9.21,1.25,12.33,3.76a19.88,19.88,0,0,1,4.78,6.34,22.29,22.29,0,0,1,19.32-10.1q12.92,0,17.58,9.33,2.52,5,2.51,13.51V92.76H1039V48.26q0-6.4-3.2-8.79a12.64,12.64,0,0,0-7.81-2.39,15.49,15.49,0,0,0-10.91,4.24q-4.57,4.24-4.58,14.18V92.76h-10.94V51q0-6.51-1.56-9.51Q997.54,37,990.83,37a15.79,15.79,0,0,0-11.09,4.72q-5,4.73-5,17.11v34H964Z" fill="#fff"/>
            <path d="M1105.58,30.47a24.24,24.24,0,0,1,9.69,8.28,28.81,28.81,0,0,1,4.28,11.3,74.9,74.9,0,0,1,1,14.12h-46.59q.3,9.78,4.57,15.68t13.23,5.91q8.37,0,13.35-5.59a19.05,19.05,0,0,0,4-7.51h10.58a22.24,22.24,0,0,1-2.78,7.87,28,28,0,0,1-5.27,7.08,24.8,24.8,0,0,1-12,6.46,36.2,36.2,0,0,1-8.72,1,26.71,26.71,0,0,1-20.15-8.7q-8.27-8.7-8.26-24.37,0-15.43,8.32-25.06t21.75-9.63A28.81,28.81,0,0,1,1105.58,30.47Zm3.85,25.15a28,28,0,0,0-3-11.19q-4.4-7.77-14.65-7.77A16.16,16.16,0,0,0,1079.43,42a20.23,20.23,0,0,0-5.28,13.61Z" fill="#fff"/>
            <path d="M1134.14,28.7h10.22v9.09a28.7,28.7,0,0,1,9.63-8.07,25.62,25.62,0,0,1,11.31-2.45q13.64,0,18.42,9.51,2.63,5.2,2.63,14.89V92.76H1175.4V52.39a21.8,21.8,0,0,0-1.73-9.45q-2.87-6-10.41-6a21.53,21.53,0,0,0-6.28.77,16.12,16.12,0,0,0-7.77,5.27,15.74,15.74,0,0,0-3.5,6.55,43.88,43.88,0,0,0-.81,9.65V92.76h-10.76Z" fill="#fff"/>
            <path d="M1204.41,10.82h10.89V28.7h10.22v8.79H1215.3V79.3c0,2.23.75,3.73,2.27,4.49a9.67,9.67,0,0,0,4.19.65c.51,0,1.07,0,1.67,0a20.46,20.46,0,0,0,2.09-.2v8.56a23.86,23.86,0,0,1-3.85.78,39,39,0,0,1-4.34.23q-7.53,0-10.23-3.85a17.19,17.19,0,0,1-2.69-10V37.49h-8.67V28.7h8.67Z" fill="#fff"/>
          </g>
        </svg>}
        {!lines && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1229.79 121.53">
          <title>Fulcrum Management</title>
          <g>
            <path d="M5.54,4.72H68.4V20.21H23.84V40.48H62.9V55.8H23.84v37H5.54Z" fill="#fff"/>
            <path d="M96,27.57V66.86q0,5.56,1.31,8.37,2.32,5,9.1,5,8.7,0,11.91-7.06A25.68,25.68,0,0,0,120,63V27.57h17.29V92.76H120.67V83.55c-.16.2-.56.8-1.19,1.79A11.72,11.72,0,0,1,117.21,88a23.6,23.6,0,0,1-7.72,4.91,26.48,26.48,0,0,1-8.74,1.31q-14.43,0-19.44-10.4Q78.51,78,78.51,66.86V27.57Z" fill="#fff"/>
            <path d="M171.21,92.76H154.16V4.6h17.05Z" fill="#fff"/>
            <path d="M226.83,51a15.14,15.14,0,0,0-2.46-6.58q-2.88-3.94-8.93-3.95-8.64,0-11.81,8.56A35.47,35.47,0,0,0,202,61a32.6,32.6,0,0,0,1.68,11.51q3.06,8.14,11.51,8.14,6,0,8.51-3.23a16.09,16.09,0,0,0,3.06-8.38h17.41a28.55,28.55,0,0,1-5.63,14.72Q230.46,95,214.72,95t-23.17-9.33q-7.43-9.33-7.42-24.19,0-16.79,8.2-26.11T215,26q12.27,0,20.09,5.5T244.3,51Z" fill="#fff"/>
            <path d="M292.17,26l1.47.09V43.59c-1.08-.12-2-.19-2.87-.23s-1.52-.06-2-.06q-10.28,0-13.81,6.69c-1.32,2.52-2,6.38-2,11.61V92.76H255.78V27.57h16.27V38.93Q276,32.41,278.93,30q4.79-4,12.44-4C291.68,26,292,26,292.17,26Z" fill="#fff"/>
            <path d="M320.61,27.57V66.86q0,5.56,1.31,8.37,2.32,5,9.11,5,8.69,0,11.9-7.06A25.67,25.67,0,0,0,344.6,63V27.57h17.28V92.76H345.31V83.55c-.16.2-.55.8-1.19,1.79A11.94,11.94,0,0,1,341.86,88a23.64,23.64,0,0,1-7.73,4.91,26.44,26.44,0,0,1-8.74,1.31Q311,94.19,306,83.79q-2.81-5.75-2.8-16.93V27.57Z" fill="#fff"/>
            <path d="M459.79,27.8a19,19,0,0,1,7.59,5.87A18.66,18.66,0,0,1,471.09,42a57.65,57.65,0,0,1,.6,9.63l-.12,41.09H454.11V51.25a13.58,13.58,0,0,0-1.2-6.1q-2.28-4.55-8.37-4.55-7.07,0-9.75,5.87a18.32,18.32,0,0,0-1.38,7.47V92.76H416.25V53.94q0-5.8-1.2-8.43c-1.43-3.15-4.25-4.73-8.43-4.73q-7.31,0-9.81,4.73-1.38,2.68-1.38,8V92.76H378.15V27.69h16.57v9.5a27.27,27.27,0,0,1,6-7.23q5-3.83,12.86-3.83,7.47,0,12.08,3.29a19.21,19.21,0,0,1,5.62,7.83,21.73,21.73,0,0,1,8.31-8.43,25.44,25.44,0,0,1,11.73-2.69A22.8,22.8,0,0,1,459.79,27.8Z" fill="#fff"/>
            <path d="M522.47,4.9h17l25.26,74.28L589.87,4.9H606.8V92.76H595.44V40.9c0-1.79,0-4.76.11-8.91s.12-8.59.12-13.34L570.59,92.76H558.8L533.47,18.65v2.7q0,3.22.18,9.83t.18,9.72V92.76H522.47Z" fill="#fff"/>
            <path d="M657.7,54q3.7-.48,5-3.1a9.51,9.51,0,0,0,.72-4.15c0-3.68-1.31-6.35-3.91-8s-6.34-2.49-11.19-2.49q-8.41,0-11.94,4.57-2,2.52-2.57,7.51h-10Q624,36.44,631.4,31.8a31.41,31.41,0,0,1,17.12-4.65q11.28,0,18.34,4.3t7,13.4V81.76a4.73,4.73,0,0,0,.68,2.69c.46.67,1.43,1,2.9,1q.72,0,1.62-.09c.6-.06,1.23-.15,1.91-.27v8a29.23,29.23,0,0,1-3.83.89,27.71,27.71,0,0,1-3.58.18q-5.56,0-8.08-3.94a15.24,15.24,0,0,1-1.85-5.92,27.48,27.48,0,0,1-9.45,7.47,29.31,29.31,0,0,1-13.58,3.17q-8.91,0-14.56-5.41A17.92,17.92,0,0,1,620.38,76q0-8.89,5.56-13.79t14.59-6ZM635,83.08a12.64,12.64,0,0,0,8.07,2.68,24.62,24.62,0,0,0,11-2.63q9-4.35,9-14.27V60.2A18.38,18.38,0,0,1,658,62.3a43.07,43.07,0,0,1-6.09,1.2l-6.51.84a25.13,25.13,0,0,0-8.8,2.44q-5,2.81-5,9A8.86,8.86,0,0,0,635,83.08Z" fill="#fff"/>
            <path d="M691.49,28.7h10.23v9.09a28.7,28.7,0,0,1,9.63-8.07,25.55,25.55,0,0,1,11.3-2.45q13.64,0,18.42,9.51,2.63,5.2,2.63,14.89V92.76H732.76V52.39A21.79,21.79,0,0,0,731,42.94q-2.87-6-10.4-6a21.44,21.44,0,0,0-6.28.77A16.1,16.1,0,0,0,706.56,43a15.86,15.86,0,0,0-3.5,6.55,43.88,43.88,0,0,0-.81,9.65V92.76H691.49Z" fill="#fff"/>
            <path d="M793.94,54q3.7-.48,5-3.1a9.68,9.68,0,0,0,.71-4.15q0-5.52-3.91-8t-11.19-2.49q-8.41,0-11.94,4.57-2,2.52-2.56,7.51H760q.3-11.88,7.68-16.52a31.38,31.38,0,0,1,17.11-4.65q11.29,0,18.34,4.3t7,13.4V81.76a4.73,4.73,0,0,0,.69,2.69c.46.67,1.42,1,2.9,1a16.09,16.09,0,0,0,1.61-.09q.9-.09,1.92-.27v8a29.61,29.61,0,0,1-3.83.89,27.89,27.89,0,0,1-3.59.18q-5.56,0-8.07-3.94a15.23,15.23,0,0,1-1.86-5.92,27.29,27.29,0,0,1-9.45,7.47,29.24,29.24,0,0,1-13.57,3.17q-8.93,0-14.57-5.41A17.92,17.92,0,0,1,756.62,76q0-8.89,5.56-13.79t14.6-6ZM771.27,83.08a12.69,12.69,0,0,0,8.08,2.68,24.58,24.58,0,0,0,11-2.63q9-4.35,9-14.27V60.2a18.25,18.25,0,0,1-5.07,2.1,43.07,43.07,0,0,1-6.09,1.2l-6.5.84a25.06,25.06,0,0,0-8.8,2.44q-5,2.81-5,9A8.85,8.85,0,0,0,771.27,83.08Z" fill="#fff"/>
            <path d="M863.34,31.27a29.42,29.42,0,0,1,6.2,6.1V29.3h9.93V87.55q0,12.21-3.58,19.26-6.67,13-25.21,13-10.32,0-17.35-4.63t-7.87-14.51h10.95a12.13,12.13,0,0,0,3.09,6.64q3.63,3.59,11.42,3.59,12.32,0,16.12-8.73,2.26-5.14,2.08-18.36a21,21,0,0,1-7.74,7.29q-4.53,2.4-12,2.4a25.53,25.53,0,0,1-18.14-7.39q-7.77-7.4-7.77-24.43,0-16.09,7.83-25.12t18.92-9A23.18,23.18,0,0,1,863.34,31.27Zm1.32,11.55a15.59,15.59,0,0,0-12.44-5.74q-11.29,0-15.47,10.64-2.19,5.69-2.2,14.89,0,10.83,4.38,16.48a14.07,14.07,0,0,0,11.75,5.65q11.54,0,16.24-10.46a33.87,33.87,0,0,0,2.62-13.82Q869.54,48.57,864.66,42.82Z" fill="#fff"/>
            <path d="M935.42,30.47a24.24,24.24,0,0,1,9.69,8.28,28.65,28.65,0,0,1,4.28,11.3,74.9,74.9,0,0,1,1,14.12H903.75q.3,9.78,4.57,15.68t13.23,5.91q8.37,0,13.36-5.59a19,19,0,0,0,4-7.51h10.59a22.28,22.28,0,0,1-2.79,7.87,28,28,0,0,1-5.27,7.08,24.76,24.76,0,0,1-12,6.46,36.2,36.2,0,0,1-8.72,1,26.73,26.73,0,0,1-20.15-8.7q-8.26-8.7-8.26-24.37,0-15.43,8.32-25.06t21.76-9.63A28.8,28.8,0,0,1,935.42,30.47Zm3.86,25.15a28.18,28.18,0,0,0-3-11.19q-4.38-7.77-14.65-7.77A16.16,16.16,0,0,0,909.27,42,20.33,20.33,0,0,0,904,55.62Z" fill="#fff"/>
            <path d="M964,28.7h10.65v9.09a36,36,0,0,1,6.93-6.88,20.89,20.89,0,0,1,12.08-3.64c5.11,0,9.21,1.25,12.33,3.76a19.88,19.88,0,0,1,4.78,6.34,22.29,22.29,0,0,1,19.32-10.1q12.92,0,17.58,9.33,2.52,5,2.51,13.51V92.76H1039V48.26q0-6.4-3.2-8.79a12.64,12.64,0,0,0-7.81-2.39,15.49,15.49,0,0,0-10.91,4.24q-4.57,4.24-4.58,14.18V92.76h-10.94V51q0-6.51-1.56-9.51Q997.54,37,990.83,37a15.79,15.79,0,0,0-11.09,4.72q-5,4.73-5,17.11v34H964Z" fill="#fff"/>
            <path d="M1105.58,30.47a24.24,24.24,0,0,1,9.69,8.28,28.81,28.81,0,0,1,4.28,11.3,74.9,74.9,0,0,1,1,14.12h-46.59q.3,9.78,4.57,15.68t13.23,5.91q8.37,0,13.35-5.59a19.05,19.05,0,0,0,4-7.51h10.58a22.24,22.24,0,0,1-2.78,7.87,28,28,0,0,1-5.27,7.08,24.8,24.8,0,0,1-12,6.46,36.2,36.2,0,0,1-8.72,1,26.71,26.71,0,0,1-20.15-8.7q-8.27-8.7-8.26-24.37,0-15.43,8.32-25.06t21.75-9.63A28.81,28.81,0,0,1,1105.58,30.47Zm3.85,25.15a28,28,0,0,0-3-11.19q-4.4-7.77-14.65-7.77A16.16,16.16,0,0,0,1079.43,42a20.23,20.23,0,0,0-5.28,13.61Z" fill="#fff"/>
            <path d="M1134.14,28.7h10.22v9.09a28.7,28.7,0,0,1,9.63-8.07,25.62,25.62,0,0,1,11.31-2.45q13.64,0,18.42,9.51,2.63,5.2,2.63,14.89V92.76H1175.4V52.39a21.8,21.8,0,0,0-1.73-9.45q-2.87-6-10.41-6a21.53,21.53,0,0,0-6.28.77,16.12,16.12,0,0,0-7.77,5.27,15.74,15.74,0,0,0-3.5,6.55,43.88,43.88,0,0,0-.81,9.65V92.76h-10.76Z" fill="#fff"/>
            <path d="M1204.41,10.82h10.89V28.7h10.22v8.79H1215.3V79.3c0,2.23.75,3.73,2.27,4.49a9.67,9.67,0,0,0,4.19.65c.51,0,1.07,0,1.67,0a20.46,20.46,0,0,0,2.09-.2v8.56a23.86,23.86,0,0,1-3.85.78,39,39,0,0,1-4.34.23q-7.53,0-10.23-3.85a17.19,17.19,0,0,1-2.69-10V37.49h-8.67V28.7h8.67Z" fill="#fff"/>
          </g>
        </svg>}
      </Link>
    </Holder>
  )
}

Logo.propTypes = {
  lines: PropTypes.bool
};

Logo.defaultProps = {
  lines: false,
};

export default Logo;
