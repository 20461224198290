import React from 'react';
import styled from 'styled-components';
import Logo from '../atoms/Logo';
import LargeNavigation from './LargeNavigation';

const Holder = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  display: none;
  @media(${props => props.theme.breakpoints.lg}) {
    display: block;
  }
`;

const LogoRow = styled.div`
  width: 100%;
  display: grid;
  align-items: start;
  justify-items: start;
  grid-template-columns: auto 1fr;
  grid-gap: 2rem;
  position: relative;
  max-width: 1300px;
  margin: 0 auto;
  padding: 1rem 2rem 2rem;
`;

const Background = styled.div`
  background: rgb(12,12,12);
  background: linear-gradient(0deg, rgba(12,12,12,0) 0%, rgba(12,12,12,0.7) 70% );
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const LogoHolder = styled.div`
  width: 22rem;
  margin-right: 3rem;
  padding-top: 0.5rem;
`;

function LargeHeader() {
  return (
    <Holder>
      <Background/>
      <LogoRow>
        <LogoHolder>
          <Logo lines={true}/>
        </LogoHolder>
        <LargeNavigation/>
      </LogoRow>
    </Holder>
  )
}

export default LargeHeader;
